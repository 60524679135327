<template>
  <b-modal
    v-model="showModal"
    ref="claim-modal"
    size="xmd"
    :title="modalTitle"
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    scrollable
    @hidden="onCloseModal"
  >
    <div>
      <b-row>
        <!-- Detail -->
        <b-col lg="6" xl="5" class="border-right">
          <div class="scroll-kanban max-height-content">
            <b-row
              class="py-1 px-1 mx-1 my-1"
              :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
            >
              <span class="col-md-4">Department</span>
              <div class="col-md-8">
                <b-icon icon="building" class="bg-orange-i mr-1"></b-icon>
                <strong> {{ employee_claim.module }}</strong>
              </div>
            </b-row>
            <b-row
              class="py-1 px-1 mx-1 my-1"
              :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
            >
              <span class="col-md-4">Claimer</span>
              <div class="col-md-8">
                <b-icon icon="person-fill" class="bg-orange-i mr-1"></b-icon>
                <strong> {{ employee_claim.username }}</strong>
              </div>
            </b-row>

            <b-row
              class="py-1 px-1 mx-1 my-1"
              :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
            >
              <span class="col-md-4">Type</span>
              <div class="col-md-8">
                <strong>{{ employee_claim.claim_type }}</strong>
              </div>
            </b-row>

            <b-row
              class="py-1 px-1 mx-1 my-1"
              :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
            >
              <span class="col-md-4">Title</span>
              <div class="col-md-8 text-truncate">
                <strong :title="employee_claim.title">{{
                  employee_claim.title
                }}</strong>
              </div>
            </b-row>

            <b-row
              class="py-1 px-1 mx-1"
              :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
            >
              <span class="col-md-4">Status</span>
              <div class="col-md-8">
                <v-select
                  ref="status"
                  size="sm"
                  class="w-100"
                  :clearable="false"
                  v-model="employee_claim.status"
                  :options="claim_status"
                  :searchable="false"
                  :reduce="(status) => status.id"
                  :selectable="(status) => status.disabled !== true"
                  :disabled="
                    userIsNotAllowedToAttend ||
                    currentUser.user_id == employee_claim.claimer_id
                  "
                  @input="onSelectStatus"
                  label="label"
                >
                  <template #option="{ label, id }">
                    <span class="ml-50 p-0">
                      <tabler-icon
                        :icon="getIcon(id)"
                        :class="getColor(id)"
                        size="20"
                        class="mr-1"
                      />
                      {{ label }}
                    </span>
                  </template>

                  <template #selected-option="{ label, id }">
                    <span class="ml-50 p-0">
                      <tabler-icon
                        :icon="getIcon(id)"
                        :class="getColor(id)"
                        size="20"
                        class="mr-1"
                      />

                      {{ label }}
                    </span>
                  </template>
                </v-select>
              </div>
            </b-row>

            <b-row
              class="py-1 px-2 mx-1 my-1"
              :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
            >
              <span class="col-md-4">Description</span>
              <div class="col-md-8">
                <b-form-textarea
                  v-model="employee_claim.description"
                  disabled
                  rows="3"
                  max-rows="10"
                  class="w-100"
                ></b-form-textarea>
              </div>
            </b-row>
            <template v-if="employee_claim.files">
              <b-row
                v-if="employee_claim.files.length > 0"
                class="p-1 mx-1 my-1"
                :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
              >
                <span class="col-md-4">Files</span>
                <b-col
                  cols="12"
                  class="cursor-pointer text-truncate"
                  v-for="(file, index) in employee_claim.files"
                  :key="index"
                >
                  <b-badge
                    @click="openFile(file)"
                    variant="light-primary"
                    style="margin: 0.5rem 0rem"
                  >
                    <feather-icon
                      icon="FileIcon"
                      class="bg-orange-i"
                      style="font-size: 1.5rem"
                    />
                    <span class="ml-1"
                      >{{ formatLongFilename(file.name, 30) }}
                      {{ file.size }}</span
                    >
                  </b-badge>
                </b-col>
              </b-row>
            </template>
          </div>
        </b-col>
        <!-- Chat -->
        <b-col lg="6" xl="7" ref="chat">
          <div class="py-1 px-2 scroll-kanban max-height-content">
            <div
              v-if="employee_claim.files"
              class="my-1 rounded card-comments"
              :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
              ref="scrollList"
              :style="
                employee_claim.files.length > 0
                  ? 'max-height: 70vh;min-height: 70vh;'
                  : 'max-height: 50vh;min-height: 50vh;'
              "
            >
              <template v-if="comments.length > 0">
                <template v-for="(comment, index) in comments">
                  <div
                    :key="index"
                    :class="{
                      'cursor-pointer': comment.is_file == 2,
                      me: comment.created_by == currentUser.user_id,
                      other: comment.created_by != currentUser.user_id,
                      center: comment.created_by == 0,
                      'mt-3': comment.is_file == 3,
                    }"
                    class="p-1 mb-1 content-comment rounded"
                    :style="backgroundComment(comment.sender)"
                  >
                    <span
                      :style="
                        comment.created_by == currentUser.user_id
                          ? 'color: #BB1EF5'
                          : 'color: #2BC8CA'
                      "
                      class="font-weight-bold"
                    >
                      {{ comment.user_name }}
                    </span>
                    <br />

                    <!-- Text -->
                    <span v-if="comment.is_file === 2">
                      <change-sms :SmsData="comment.content"
                    /></span>
                    <!-- Survey -->
                    <div v-else-if="comment.is_file === 3" class="text-center">
                      <span class="text-bold"> {{ comment.content }}</span>
                      <SurveyEmojiCard
                        class="p-2"
                        @feedback="sendFeedback"
                        :survey="survey"
                      />
                    </div>
                    <!-- Images -->
                    <span
                      @click="showFile(comment)"
                      v-else-if="isImage(comment)"
                    >
                      <b-img
                        class="my-1"
                        style="max-height: 200px"
                        :src="comment.file_url"
                        :alt="comment.content"
                        fluid
                        thumbnail
                      ></b-img>
                    </span>
                    <!-- Audio  -->
                    <span v-else-if="isAudio(comment)">
                      <Wavesurfer
                        class="mb-1"
                        style="height: 1.5rem"
                        :url="comment.file_url"
                        :options="audioOptions"
                        :wave-surfer-id="`wave-surfer-${index}`"
                        :elapsed-time-width="50"
                      />
                    </span>

                    <span v-else @click="showFile(comment)">
                      <b-badge
                        variant="light-primary"
                        style="margin: 0.5rem 0rem"
                      >
                        <feather-icon
                          icon="FileIcon"
                          class="bg-orange-i"
                          style="font-size: 1.5rem"
                        />
                        <span class="ml-1">{{
                          formatLongFilename(comment.content, 30)
                        }}</span>
                        ({{ comment.file_size }})
                      </b-badge>
                    </span>
                    <div
                      class="ml-4 text-right"
                      style="color: #6e6b7b"
                      v-if="comment.is_file !== 3"
                    >
                      {{ comment.date_time | myGlobalDay }}
                    </div>
                  </div>
                </template>
              </template>
              <template v-else>
                <div class="text-center">
                  <span class="no-message">No messages yet</span>
                </div>
              </template>
            </div>

            <!-- Buttons  -->
            <b-row v-if="!finishedClaim">
              <b-col cols="10" class="pr-0">
                <!-- Message input -->
                <b-form-textarea
                  id="textarea"
                  style="
                    height: 3em !important;
                    line-height: 1.5 !important;
                    padding: 0.5rem !important;
                  "
                  @keydown.enter.prevent="sendMessage"
                  v-model="send.message"
                  placeholder="Enter something..."
                  rows="1"
                  max-rows="1"
                  no-resize
                >
                </b-form-textarea>
              </b-col>
              <b-col cols="2" class="text-center d-flex align-items-center">
                <!-- Attach File -->
                <div>
                  <b-avatar
                    id="tooltip-button-1"
                    :badge="send.files.length > 0"
                    :badge-top="send.files.length > 0"
                    :badge-offset="send.files.length > 0 ? '-0.5em' : ''"
                    button
                    variant="light-secondary"
                    class="mr-1dot3"
                  >
                    <template #badge v-if="send.files.length > 0">
                      <span class="badge-value cursor-pointer">{{
                        send.files.length
                      }}</span>
                      <b-tooltip target="tooltip-button-1" placement="top">
                        <div
                          v-for="(file, index) in send.files"
                          :key="index"
                          class="d-flex align-items-center justify-content-between"
                        >
                          <div class="d-flex align-items-center">
                            <feather-icon
                              icon="FileIcon"
                              class="bg-orange-i my-1"
                              style="font-size: 1.5rem"
                            />
                            <span class="ml-1">{{
                              formatLongFilename(file.name, 15)
                            }}</span>
                          </div>
                          <feather-icon
                            icon="XIcon"
                            class="bg-orange-i cursor-pointer"
                            style="font-size: 1.5rem"
                            @click="removeFile(index)"
                          />
                        </div>
                      </b-tooltip>
                    </template>
                    <template #default>
                      <feather-icon
                        icon="PaperclipIcon"
                        class="bg-orange-i"
                        title="Attach"
                        @click="attachFile()"
                      />
                    </template>
                  </b-avatar>
                </div>
                <!-- multiple -->
                <input
                  type="file"
                  accept="image/*, audio/*, video/*, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx"
                  ref="file"
                  style="display: none"
                  @change="onFileChange"
                />

                <!-- Send button -->
                <div class="cursor-pointer" @click="sendMessage()">
                  <feather-icon
                    icon="SendIcon"
                    class="bg-primary-i"
                    title="Send"
                  />
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import EmployeeClaimService from "@/views/commons/employee-claims/services/employee-claims.service.js";
import EmployeeClaimStatus from "@/views/commons/employee-claims/data/employee-claim-status.js";
import SurveyEmojiCard from "@/views/commons/employee-claims/components/SurveryEmojiCard.vue";
import Wavesurfer from "@/views/management/views/approval-process/settlement-approval/components/WaveSurferPlayer.vue";
import ChangeSms from "@/views/commons/components/clients/components/clientsSms/ChangeSms";

export default {
  name: "ModalAttendClaim",
  components: {
    SurveyEmojiCard,
    Wavesurfer,
    ChangeSms,
  },
  props: {
    claimId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      usersAllowedToAttendClaims: [],
      audioOptions: {
        waveColor: "#99989A",
        progressColor: "#0077E6",
        barWidth: 3,
        barRadius: 3,
        cursorWidth: 0,
        height: 20,
        barGap: 3,
        width: 180,
        backend: "MediaElement",
        minPxPerSec: 5,
        normalizeToZero: true,
        waveOutlineWidth: 0,
        barMinHeight: 2,
      },
      showModal: true,
      employee_claim: {
        files: [],
      },
      comments: [],
      type_message: null,
      send: {
        files: [],
        message: "",
      },
      oldStatusValue: null,
      oldStatus: null,
      showFilesContent: "",
      showFilesTooltip: false,
      claim_status: [
        {
          id: 1,
          label: "Draft",
          disabled: true,
        },
        {
          id: 2,
          label: "Pending",
          disabled: false,
        },
        {
          id: 3,
          label: "In Revision",
          disabled: false,
        },
        {
          id: 4,
          label: "Resolved",
          disabled: false,
        },
        {
          id: 5,
          label: "Rejected",
          disabled: false,
        },
      ],
      survey: {
        rate: null,
        comment: null,
      },
    };
  },
  async mounted() {
    await this.getUsersForAttendEmployeeClaims();
    await this.getEmployeeClaim();
    this.setOrigin();
  },
  created() {
    this.activeChatSocket();
  },
  beforeDestroy() {
    try {
      window.socket.unbind("new-message-in-claim-chat");
    } catch (error) {
      console.log("new-message-in-claim-chat", error);
    }
  },
  methods: {
    formatLongFilename(filename, maxLength) {
      let formattedTitle = filename.substring(0, maxLength);

      if (filename.length > maxLength) {
        formattedTitle += "..........";
      }

      const regex = /\((.*?)\)$/;
      const match = regex.exec(filename);

      if (match && match.length >= 2) {
        const content = match[1];
        formattedTitle += " (" + content + ")";
      }

      return formattedTitle;
    },
    isImage(comment) {
      return (
        (comment.is_file === 1 && comment.file_type === "jpg") ||
        comment.file_type === "png" ||
        comment.file_type === "jpeg" ||
        comment.file_type === "gif"
      );
    },
    isAudio(comment) {
      return comment.is_file === 1 && comment.file_type === "mp3";
    },
    disabledStatusSelect() {
      if (this.employee_claim.status === 3) {
        this.claim_status[0].disabled = true;
        this.claim_status[1].disabled = true;
        this.claim_status[2].disabled = true;
      }
      if (
        this.employee_claim.status === 4 ||
        this.employee_claim.status === 5
      ) {
        this.claim_status[0].disabled = true;
        this.claim_status[1].disabled = true;
        this.claim_status[2].disabled = true;
        this.claim_status[3].disabled = true;
        this.claim_status[4].disabled = true;
      }
    },
    async activeChatSocket() {
      try {
        window.socket.unsubscribe("channel-chat");
        window.socket.subscribe("channel-chat");
        window.socket.bind("new-message-in-claim-chat", async (response) => {
          const { data } = response;
          if (parseInt(data.claim_request_id) === this.employee_claim.id) {
            if (data.type === "status") {
              this.employee_claim.status = parseInt(data.status);
              this.addSurveyButtonInChat();
              this.disabledStatusSelect();
            } else if (data.type === "message") {
              this.comments.push(data.message);
              this.scrollCommentsBottom();
            } else if (data.type === "file") {
              this.comments.push(data.message);
              this.scrollCommentsBottom();
            }
          }
        });
      } catch (error) {
        console.log("new-message-in-claim-chat", error);
      }
    },
    onFileChange(e) {
      const fileArray = Array.from(e.target.files);
      const newFileArray = [];
      fileArray.forEach((file) => {
        newFileArray.push(file);
      });
      this.send.files = newFileArray;
    },
    async sendFeedback($event) {
      try {
        let params = {
          claim_request_id: this.employee_claim.id,
          comment: $event.comment,
          rate: $event.rate,
        };
        const { data, status } =
          await EmployeeClaimService.setClaimRequestSurvey(params);
        if (status === 200) {
          this.comments = this.comments.filter(
            (comment) => comment.is_file !== 3
          );
          this.showToast(
            "success",
            "top-right",
            "Success",
            "CheckIcon",
            data.message
          );
          this.$emit("close");
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    getIcon(id) {
      if (id === 1) {
        return "FlagIcon";
      }
      if (id === 2) {
        return "ClockIcon";
      }
      if (id === 3) {
        return "GitCompareIcon";
      }
      if (id === 4) {
        return "CircleCheckIcon";
      }
      if (id === 5) {
        return "CircleMinusIcon";
      }
    },
    getColor(id) {
      if (id === 1) {
        return "text-primary";
      }
      if (id === 2) {
        return "text-warning";
      }
      if (id === 3) {
        return "text-info";
      }
      if (id === 4) {
        return "text-success";
      }
      if (id === 5) {
        return "text-danger";
      }
    },
    setOrigin() {
      if (this.currentUser.user_id === this.employee_claim.created_by) {
        this.type_message = "Claimer";
      } else {
        this.type_message = "Department";
      }
    },
    onCloseModal() {
      this.$emit("close");
      this.updateCounter();
    },
    openFile(file) {
      window.open(file.path, "_blank");
    },
    attachFile() {
      this.$refs.file.click();
    },
    async onSelectStatus() {
      try {
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          this.addPreloader();
          let form = new FormData();
          form.append("module_id", this.employee_claim.module_id);
          form.append("created_by", this.employee_claim.created_by);
          form.append("updated_by", this.currentUser.user_id);
          form.append("claim_type_id", this.employee_claim.claim_type_id);
          form.append("id", this.employee_claim.id);
          form.append("title", this.employee_claim.title);
          form.append("description", this.employee_claim.description);
          form.append("status", this.employee_claim.status);
          form.append("in_schedule", this.employee_claim.in_schedule);
          const { status } =
            await EmployeeClaimService.saveOrUpdateEmployeeClaim(form);
          if (status === 200) {
            if([4,5].includes(this.employee_claim.status)){
              this.$emit("removeItem");
            }
            this.disabledStatusSelect();
            // maybe execute socket in backend to move claim to another tab
            this.$emit("close");
          }
        } else {
          this.employee_claim.status = this.oldStatusValue;
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    backgroundComment(type) {
      let boxShadow = "0 1px 3px rgb(0 0 0 / 20%)";
      let style = "";
      if (type == "Claimer") {
        style = `background: ${
          this.isDarkSkin ? `rgb(32 32 36)` : "rgb(255 255 255)"
        }!important;`;
      } else if (type == "Department") {
        style = `background: ${
          this.isDarkSkin ? `rgb(16 16 18)` : "rgb(228 234 236)"
        }!important;`;
      } else {
        style = `background: ${
          this.isDarkSkin ? `rgb(23 23 26)` : "rgb(255 246 244) "
        }!important;`;
      }

      return `${style} box-shadow: ${
        !this.isDarkSkin ? boxShadow : ""
      }!important;`;
    },
    showFile(item) {
      if (item.file_url === null) return;
      window.open(item.file_url);
    },
    removeFile(index) {
      this.send.files.splice(index, 1);
    },
    async sendMessage() {
      if (event.shiftKey && event.keyCode === 13) {
        // Shift + Enter pressed, do nothing or perform a different action
        return;
      }

      if (!this.send.message && this.send.files.length === 0) {
        return this.showToast(
          "warning",
          "top-right",
          "Warning",
          "AlertTriangleIcon",
          "Please enter a message or attach a file"
        );
      }

      try {
        this.addPreloader();
        let form = new FormData();
        form.append("claim_request_id", this.employee_claim.id);
        form.append("created_by", this.currentUser.user_id);
        form.append("message", this.send.message);
        form.append("sender", this.type_message == "Claimer" ? 1 : 2);
        if (this.send.files.length > 0) {
          this.send.files.forEach((file) => {
            form.append("files[]", file);
          });
        }

        const { status } = await EmployeeClaimService.createClaimMessage(form);
        if (status === 200) {
          this.send.message = null;
          // this.comments = data.comments;
          this.send.files = [];
        }
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },
    async getEmployeeClaim() {
      try {
        this.addPreloader();
        const params = {
          claim_request_id: this.claimId,
        };
        const { data } = await EmployeeClaimService.getEmployeeClaim(params);
        this.employee_claim = data.claim_request;
        this.oldStatusValue = this.employee_claim.status;
        this.oldStatus = data.claim_request.status;
        if (this.employee_claim.status == EmployeeClaimStatus.PENDING) {
          await this.updateToRevision();
        }
        this.disabledStatusSelect();

        if (data.claim_request.files) {
          this.employee_claim.files = data.claim_request.files;
        } else {
          this.employee_claim.files = [];
        }
        this.searchMessageHistory();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async updateToRevision() {
      try {
        if (
          this.currentUser.user_id !== this.employee_claim.created_by &&
          this.currentUser.user_id !== 1
        ) {
          let form = new FormData();
          form.append("module_id", this.employee_claim.module_id);
          form.append("created_by", this.employee_claim.created_by);
          form.append("claim_type_id", this.employee_claim.claim_type_id);
          form.append("id", this.employee_claim.id);
          form.append("title", this.employee_claim.title);
          form.append("description", this.employee_claim.description);
          form.append("status", EmployeeClaimStatus.IN_REVISION);
          form.append("in_schedule", this.employee_claim.in_schedule);
          form.append("updated_by", this.currentUser.user_id);

          const { data, status } =
            await EmployeeClaimService.saveOrUpdateEmployeeClaim(form);
          if (status === 200) {
            this.employee_claim.status = parseInt(data.claim_status);
            this.disabledStatusSelect();
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async searchMessageHistory() {
      try {
        this.addPreloader();
        const params = {
          claim_request_id: this.claimId,
          origin: this.type_message == "Claimer" ? 1 : 2,
        };
        const { data } = await EmployeeClaimService.searchMessageHistory(
          params
        );
        this.comments = data.comments;
        this.addSurveyButtonInChat();
        this.scrollCommentsBottom();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    scrollCommentsBottom() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.scrollList.scrollTop = this.$refs.scrollList.scrollHeight;
        }, 1000);
      });
    },
    addSurveyButtonInChat() {
      let claimer_id = this.employee_claim.claimer_id;
      if (
        (this.employee_claim.status === EmployeeClaimStatus.RESOLVED ||
          this.employee_claim.status === EmployeeClaimStatus.REJECTED) &&
        claimer_id == this.currentUser.user_id
      ) {
        this.survey.comment = this.employee_claim.survey_feedback ?? "";
        this.survey.rate = this.employee_claim.survey_rating ?? 0;
        let survey_comment = {
          is_file: 3,
          created_by: 0,
          content: "Rate your claim experience",
        };
        this.comments.push(survey_comment);
      }
    },
    updateCounter() {
      if (this.statusRouter == 1 && this.oldStatus == 2) {
        // Update counter SidebarStore Staff Claims
        const position = this.S_SIDEBAR_ITEMS.findIndex(
          (obj) => obj.title === "Staff Claims"
        );
        this.S_SIDEBAR_ITEMS[position].tag = this.S_SIDEBAR_ITEMS.tag - 1;
        this.S_COUNTER_EMPLOYEE_CLAIMS.counterModule =
          this.S_COUNTER_EMPLOYEE_CLAIMS.counterModule - 1;
      }
    },
    async getUsersForAttendEmployeeClaims() {
      try {
        const { data } =
          await EmployeeClaimService.getUsersForAttendEmployeeClaims();
        const users = data.map((item) => item.id);
        this.usersAllowedToAttendClaims = users;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
  watch: {
    "send.files": function (val) {
      this.showFilesContent = val;
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    ...mapState({
      S_SIDEBAR_ITEMS: (state) => state.SidebarStore.S_SIDEBAR_ITEMS,
      S_COUNTER_EMPLOYEE_CLAIMS: (state) =>
        state.EmployeeClaimsStore.S_COUNTER_EMPLOYEE_CLAIMS,
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    finishedClaim() {
      return (
        this.employee_claim.status === 4 || this.employee_claim.status === 5
      );
    },
    userIsNotAllowedToAttend() {
      return !this.usersAllowedToAttendClaims.includes(
        this.currentUser.user_id
      );
    },
    statusRouter() {
      return this.$route.meta.status;
    },
    modalTitle() {
      return `Employee Claim ${
        this.employee_claim.code ? this.employee_claim.code : ""
      }`;
    },
  },
};
</script>
<style scoped>
.floating-right-badge {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 10px;
  margin-right: 10px;
}
.card-comments {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  font-size: 1rem;
  overflow-y: auto;
  box-shadow: 0 0 1.2px 1px rgb(0 0 0 / 0.1);
}
.content-comment {
  max-width: 80%;
  border-radius: 20px;
  padding: 5px 10px;
}
.me {
  margin-left: auto;
}
.other {
  margin-right: auto;
}

.center {
  margin: 0 auto;
}

.bg-rgba {
  background: rgb(0 0 0 / 18%) !important;
}
.bg-rgba-light {
  background: rgb(0 0 0 / 4%) !important;
}
.bg-orange-i {
  color: #ff6045;
  font-size: 1.5rem;
}

.style-chooser.vs__dropdown-menu {
  background: #dfe5fb;
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
}

.text-truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
.mr-1dot3 {
  margin-right: 1.3rem !important;
}

.no-message {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 800;
  font-weight: bolder;
  position: absolute;
  text-transform: uppercase;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>