<template>
  <b-modal
    ref="tracking-employee-claims-modal"
    lazy
    centered
    header-bg-variant="primary"
    title-class="h3 text-white font-weight-bolder"
    size="lg"
    title="TRACKING"
    modal-class="modal-primary"
    body-class="p-0"
    hide-footer
    scrollable
    @hidden="hiddenModal"
  >
    <b-table :fields="fields" :items="myProvider" small show-empty>
      <template #cell(status)="data">
        <div class="d-flex justify-content-around">
          <b-badge
            class="text-center w-100"
            :class="colorStatus(data.item.status)[1]"
            pill
          >
            {{ colorStatus(data.item.status)[0] }}
          </b-badge>
        </div>
      </template>
      <template v-slot:cell(created_at)="data">
        {{ data.item.created_at | myGlobalWithHour }}
      </template>
    </b-table>
  </b-modal>
</template>
  
  <script>
import EmployeeClaimsService from "@/views/commons/employee-claims/services/employee-claims.service";
export default {
  name: "TrackingEmployeeClaimsModal",
  props: {
    claim: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "created_by",
          label: "Created by",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "status",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "created_at",
          label: "Date",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
    };
  },
  methods: {
    async myProvider() {
      try {
        this.addPreloader();
        const { data } = await EmployeeClaimsService.getTrackingEmployeeClaims({
          id: this.claim.id,
          attentionSection: this.claim.attentionSection,
        });
        return data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    colorStatus(type) {
      let selected;
      switch (type) {
        case 1:
          selected = ["Draft", "bg-light-secondary"];
          break;
        case 2:
          selected = ["Pending", "bg-light-warning"];
          break;
        case 3:
          selected = ["In Revision", "bg-light-primary"];
          break;
        case 4:
          selected = ["Resolved", "bg-light-success"];
          break;
        case 5:
          selected = ["Rejected", "bg-light-danger"];
          break;
      }
      return selected;
    },
    hiddenModal() {
      this.$emit("hiddenModal");
    },
  },
  mounted() {
    this.toggleModal("tracking-employee-claims-modal");
  },
};
</script>
  
  <style scoped>
</style>
  