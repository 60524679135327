<template>
  <div class="container-icon" :style="propContainer" @click="clickMe()">
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="my-icon"
    >
      <path
        d="M27.1343 54.2686C42.1202 54.2686 54.2686 42.1202 54.2686 27.1343C54.2686 12.1484 42.1202 0 27.1343 0C12.1484 0 0 12.1484 0 27.1343C0 42.1202 12.1484 54.2686 27.1343 54.2686Z"
        fill="#EF8035"
      />
      <path
        d="M18.0991 31.4586C20.4873 31.4586 22.4234 29.5226 22.4234 27.1343C22.4234 24.7461 20.4873 22.81 18.0991 22.81C15.7108 22.81 13.7748 24.7461 13.7748 27.1343C13.7748 29.5226 15.7108 31.4586 18.0991 31.4586Z"
        fill="#202020"
      />
      <path
        d="M36.1695 31.4586C38.5578 31.4586 40.4939 29.5226 40.4939 27.1343C40.4939 24.7461 38.5578 22.81 36.1695 22.81C33.7813 22.81 31.8452 24.7461 31.8452 27.1343C31.8452 29.5226 33.7813 31.4586 36.1695 31.4586Z"
        fill="#202020"
      />
      <path
        d="M34.5658 41.3387H19.6885C19.0155 41.3387 18.4714 40.7946 18.4714 40.1216C18.4714 39.4486 19.0155 38.9045 19.6885 38.9045H34.5658C35.2388 38.9045 35.7829 39.4486 35.7829 40.1216C35.7829 40.7946 35.2388 41.3387 34.5658 41.3387Z"
        fill="#202020"
      />
    </svg>
  </div>
</template>
  
  <script>
export default {
  props: {
    width: {
      type: Number,
      required: false,
      default: 50,
    },
    height: {
      type: Number,
      required: false,
      default: 50,
    },
    value: {
      type: Number,
      required: false,
      default: 3,
    },
  },

  computed: {
    propContainer() {
      return { width: this.width + 10 + "px", height: this.height + 10 + "px" };
    },
  },

  data() {
    return {};
  },

  methods: {
    clickMe() {
      this.$emit("clickMe", this.value);
    },
  },
};
</script>
  
  <style scoped>
.container-icon {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-icon {
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.my-icon:hover {
  transform: scale(1.2);
}
</style>