import { amgApi } from "@/service/axios";
class ClaimService {
    async getCLaims(params) {
        try {
            const data = await amgApi.post("commons/employee-claims/get-employee-claims", params);
            return data;
        } catch (error) {
            console.log("Error getCLaims");
        }
    }

    async getViewCLaim(params) {
        try {
            const data = await amgApi.post("commons/employee-claims/get-employee-claims-x-id", params);
            return data;
        } catch (error) {
            console.log("Error getViewCLaim");
        }
    }
}
export default new ClaimService();