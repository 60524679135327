
<template>
  <b-modal
    ref="survey-emoji-card-modal"
    centered
    hide-header
    hide-footer
    no-close-on-esc
    scrollable
    size="500"
    no-close-on-backdrop
    @hidden="onCloseModal"
  >    <div class="d-flex justify-content-end">
      <span
        ><feather-icon
          icon="XIcon"
          size="30"
          class="cursor-pointer"
          @click="onCloseModal"
        />
      </span>
    </div>
    <div>
      <SurveyEmojiCard class="p-2" @feedback="sendFeedback" :survey="survey" />
    </div>
  </b-modal>
</template>
  
  <script>
import { mapGetters, mapState } from "vuex";
import EmployeeClaimService from "@/views/commons/employee-claims/services/employee-claims.service.js";
import SurveyEmojiCard from "@/views/commons/employee-claims/components/SurveryEmojiCard.vue";

export default {
  name: "ModalAttendClaim",
  components: {
    SurveyEmojiCard,
  },
  props: {
    claim: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      comments: [],
      survey: {
        rate: null,
        comment: null,
      },
    };
  },
  methods: {
    onCloseModal() {
      this.$emit("close");
    },
    async sendFeedback($event) {
      try {
        let params = {
          claim_request_id: this.claim.id,
          comment: $event.comment,
          rate: $event.rate,
        };
        const { data, status } =
          await EmployeeClaimService.setClaimRequestSurvey(params);
        if (status === 200) {
          this.comments = this.comments.filter(
            (comment) => comment.is_file !== 3
          );
          this.showToast(
            "success",
            "top-right",
            "Success",
            "CheckIcon",
            data.message
          );
          this.$emit("close", this.claim.id);
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getEmployeeClaim() {
      try {
        this.addPreloader();
        const params = {
          claim_request_id: this.claim.id,
        };
        const { data } = await EmployeeClaimService.getEmployeeClaim(params);
        this.survey.comment = data.claim_request.survey_feedback ?? "";
        this.survey.rate = data.claim_request.survey_rating ?? 0;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
  
 async mounted() {
    await this.getEmployeeClaim();
    this.toggleModal("survey-emoji-card-modal");

  },
};
</script>
  <style scoped>
</style>