<template>
  <div class="container-icon" :style="propContainer" @click="clickMe()">
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="my-icon"
    >
      <path
        d="M27.1343 54.2686C42.1202 54.2686 54.2686 42.1202 54.2686 27.1343C54.2686 12.1484 42.1202 0 27.1343 0C12.1484 0 0 12.1484 0 27.1343C0 42.1202 12.1484 54.2686 27.1343 54.2686Z"
        fill="#63DB1C"
      />
      <path
        d="M22.2086 26.6618C22.0511 26.7907 21.822 26.805 21.6502 26.7048C17.3688 24.3421 12.7438 25.5449 11.7415 25.7454C11.7129 25.7454 11.6699 25.7597 11.6413 25.7597C11.4551 25.7597 11.269 25.6452 11.1831 25.4733C11.0542 25.2156 11.2117 24.9578 11.4408 24.786C12.1711 24.1989 16.9679 20.1753 22.2659 25.9172C22.4807 26.1177 22.452 26.4757 22.2086 26.6618Z"
        fill="#202020"
      />
      <path
        d="M32.06 26.6618C32.2175 26.7907 32.4466 26.805 32.6184 26.7048C36.8998 24.3421 41.5248 25.5449 42.5271 25.7454C42.5558 25.7454 42.5987 25.7597 42.6274 25.7597C42.8135 25.7597 42.9996 25.6452 43.0856 25.4733C43.2144 25.2156 43.0569 24.9578 42.8278 24.786C42.0976 24.1989 37.3007 20.1753 32.0027 25.9172C31.788 26.1177 31.8166 26.4757 32.06 26.6618Z"
        fill="#202020"
      />
      <path
        d="M17.5263 36.1409C17.5263 36.1409 19.0871 43.0999 27.1343 43.0999C35.11 43.0999 36.7423 36.1409 36.7423 36.1409H17.5263Z"
        fill="#202020"
      />
    </svg>
  </div>
</template>
      
<script>
export default {
  props: {
    width: {
      type: Number,
      required: false,
      default: 50,
    },
    height: {
      type: Number,
      required: false,
      default: 50,
    },
    value: {
      type: Number,
      required: false,
      default: 5,
    },
  },

  computed: {
    propContainer() {
      return { width: this.width + 10 + "px", height: this.height + 10 + "px" };
    },
  },

  data() {
    return {};
  },

  methods: {
    clickMe() {
      this.$emit("clickMe", this.value);
    },
  },
};
</script>
      
<style scoped>
.container-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-icon {
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.my-icon:hover {
  transform: scale(1.2);
}
</style>