<template>
  <div class="container-icon" :style="propContainer" @click="clickMe()">
    <svg
      :width="width"
      :height="height"
      class="my-icon"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.1343 54.2686C42.1202 54.2686 54.2686 42.1202 54.2686 27.1343C54.2686 12.1484 42.1202 0 27.1343 0C12.1485 0 0 12.1484 0 27.1343C0 42.1202 12.1485 54.2686 27.1343 54.2686Z"
        fill="#EF4B3F"
      />
      <path
        d="M18.0991 29.1247C20.4873 29.1247 22.4234 27.1886 22.4234 24.8004C22.4234 22.4121 20.4873 20.476 18.0991 20.476C15.7108 20.476 13.7748 22.4121 13.7748 24.8004C13.7748 27.1886 15.7108 29.1247 18.0991 29.1247Z"
        fill="#202020"
      />
      <path
        d="M36.1695 29.1247C38.5578 29.1247 40.4938 27.1886 40.4938 24.8004C40.4938 22.4121 38.5578 20.476 36.1695 20.476C33.7813 20.476 31.8452 22.4121 31.8452 24.8004C31.8452 27.1886 33.7813 29.1247 36.1695 29.1247Z"
        fill="#202020"
      />
      <path
        d="M34.5801 41.3387C34.1792 41.3387 33.7783 41.1382 33.5492 40.7659C33.4633 40.6228 31.3297 37.3724 27.1343 37.3724C22.9961 37.3724 20.7338 40.7516 20.7194 40.7803C20.3471 41.3387 19.5882 41.5105 19.0298 41.1382C18.4714 40.7659 18.2995 40.007 18.6718 39.4486C18.7864 39.2625 21.6931 34.9238 27.1343 34.9238C32.7044 34.9238 35.4965 39.2768 35.6111 39.4629C35.9691 40.0357 35.7972 40.7946 35.2245 41.1526C35.024 41.2671 34.8092 41.3387 34.5801 41.3387Z"
        fill="#202020"
      />
    </svg>
  </div>
</template>
  
  <script>
export default {
  props: {
    width: {
      type: Number,
      required: false,
      default: 50,
    },
    height: {
      type: Number,
      required: false,
      default: 50,
    },
    value: {
      type: Number,
      required: false,
      default: 2,
    },
  },

  computed: {
    propContainer() {
      return { width: this.width + 10 + "px", height: this.height + 10 + "px" };
    },
  },

  data() {
    return {};
  },

  methods: {
    clickMe() {
      this.$emit("clickMe", this.value);
    },
  },
};
</script>
  
  <style scoped>
.container-icon {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-icon {
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.my-icon:hover {
  transform: scale(1.2);
}
</style>