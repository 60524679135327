<template>
  <b-modal
    v-model="isShow"
    centered
    title="View Claim"
    hide-footer
    hide-header
    @hidden="close"
    size="1040"
  >
    <div class="d-flex justify-content-end">
      <span
        ><feather-icon
          icon="XIcon"
          size="30"
          class="cursor-pointer"
          @click="close"
        />
      </span>
    </div>
    <div class="mb-3 mx-3">
      <b-row>
        <b-col cols="12" class="mb-1 text-primary" style="font-size: 40px"
          ><b>Claim {{ code }}</b>
        </b-col>
      </b-row>
      <div class="mb-2">
        <b-row>
          <b-col cols="6" xl="6" lg="6" md="12" sm="12">
            <b-row>
              <b-col cols="6">
                <b-row>
                  <b-col class="custom-size-sub-title" cols="12"
                    >Requester</b-col
                  >
                  <b-col class="custom-size-sub-data" cols="12">{{
                    user
                  }}</b-col>
                </b-row>
              </b-col>
              <b-col cols="6">
                <b-row>
                  <b-col class="custom-size-sub-title" cols="12">From</b-col>
                  <b-col
                    class="custom-size-sub-data"
                    v-if="loading"
                    cols="12"
                    >{{ module_name }}</b-col
                  >
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6" xl="6" lg="6" md="12" sm="12">
            <b-row >
              <b-col class="custom-size-sub-title" cols="12">Type</b-col>
              <b-col class="custom-size-sub-data d-flex " cols="12">
                <div class="mr-auto">{{ type }}</div>
                <div>
                  <b-badge  :class="colorStatus(status)[1]">{{
                    colorStatus(status)[0] == 'Created' ? 'Draft' : colorStatus(status)[0] }}
                  </b-badge>
                </div>
                
                </b-col
              >
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col cols="6" xl="6" lg="6" md="12" sm="12">
            <b-row class="mt-1">
              <b-col class="custom-size-sub-title" cols="12">Claim</b-col>
              <b-col class="custom-size-sub-data" cols="12">{{
                title_claim
              }}</b-col>
            </b-row>
          </b-col>
          <b-col cols="6" xl="6" lg="6" md="12" sm="12">
            <b-row class="mt-1">
              <b-col class="custom-size-sub-title" cols="12">Claim Date</b-col>
              <b-col class="custom-size-sub-data" cols="12" v-if="send_date">{{
                send_date | myDateGlobalWithHour
              }}</b-col>
              <b-col class="custom-size-sub-data" cols="12" v-else>Undefined</b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <!--  -->
      <b-row>
        <b-col
          :cols="!files ? '12' : '6'"
          :xl="!files ? '12' : '6'"
          :lg="!files ? '12' : '6'"
          md="12"
          sm="12"
          class="mt-1"
        >
          <div
            :class="isDarkSkin ? 'grayColorDark' : 'grayColorWhite'"
            class="formatDiv descriptionDiv"
          >
            {{ description }}
          </div>
        </b-col>
        <b-col cols="6" v-if="files" xl="6" lg="6" md="12" sm="12" class="mt-1">
          <b-row
            :class="isDarkSkin ? 'grayColorDark' : 'grayColorWhite'"
            class="formatDiv"
            style="border-style: dashed"
          >
            <b-col
              v-for="(file, index) in files"
              :key="index"
              cols="6"
              sm="12"
              md="12"
              lg="6"
              xl="6"
              :class="
                skin === 'dark' ? 'hover-shadow-dark' : 'hover-shadow-light'
              "
            >
              <div class="d-flex flex-column">
                <div
                  v-b-tooltip.hover.b.bottom="file.name"
                  class="w-100 d-flex flex-column align-items-center"
                  @contextmenu.prevent="contentRightClicked"
                >
                  <amg-icon
                    :icon="
                      file.type === 'Folder'
                        ? 'CustomFolderIcon'
                        : 'CustomFileIcon'
                    "
                    class="font-large-2 cursor-pointer"
                    :style="file.type === 'Folder' ? 'fill: #ff9f43' : ''"
                    :class="{ 'text-warning': file.type === 'Folder' }"
                    @click="clickFile(file)"
                  />
                  <h6 class="text-center">
                    {{ file.name }}
                  </h6>
                  <h6 class="text-center">
                    {{ file.created_at | myGlobal }}
                  </h6>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import ClaimsService from "@/views/commons/employee-claims/services/claim.service.js"
import { mapGetters } from "vuex"

export default {
  components: {},
  props: {
    id_claim: {
      type: Number,
    },
  },
  data() {
    return {
      isShow: false,
      user: null,
      module_name: null,
      type: null,
      description: null,
      status: 1,
      send_date: null,
      title_claim: "",
      viewImage: "/assets/images/icons/sms-img.png",
      cantClaimUSer: 0,
      id_user: 0,
      loading: false,
      files: {},
      code:'',
    }
  },
  async created() {
    this.addPreloader();
    await this.getDataClaim()
    this.isShow = true
    this.removePreloader();
  },
  watch: {
    "S_DATA_UPDATE_CLAIM.status": {
      handler() {
        if (this.S_DATA_UPDATE_CLAIM.idUserClaim == this.id_user) {
          this.cantClaimUSer++
        }
      },
    },
  },
  methods: {
    async getDataClaim() {
      try {
        const params = {
          id: this.id_claim,
          user_id: this.currentUser.user_id,
          see: this.tabStatus == 1 ?  1 : null,
        }

        const data = await ClaimsService.getViewCLaim(params)
        if (data.status == 200) {
          this.id_user = data.data[0].id_user
          this.user = data.data[0].name_user
          this.module_name = data.data[0].name_module
          this.type = data.data[0].description_type
          this.description = data.data[0].description
          this.status = data.data[0].status
          this.send_date = data.data[0].send_date
          this.title_claim = data.data[0].title
          this.viewImage = data.data[0].img_claim_url
          this.cantClaimUSer = data.data[0].cantClaimUSer
          this.files = data.data[0].files
          this.code = data.data[0].code
        }
      } catch (e) {
        this.showErrorSwal(e)
      } finally {
        this.loading = true
      }
    },
    colorStatus(type) {
      let selected
      switch (type) {
        case 1:
          selected = ["Created", "bg-light-secondary"]
          break
        case 2:
          selected = ["Pending", "bg-light-warning"]
          break
        case 3:
          selected = ["In Revision", "bg-light-primary"]
          break
        case 4:
          selected = ["Resolved", "bg-light-success"]
          break
        case 5:
          selected = ["Rejected", "bg-light-danger"]
          break
      }
      return selected
    },
    close() {
      //this.isShow=false;
      this.$emit("close")
    },
    clickFile(content) {
      window.open(
        content.path,
        "_blank" // <- This is what makes it open in a new window.
      )
    },
  },
  computed: {
    imageShow() {
      return this.viewImage
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      S_DATA_UPDATE_CLAIM: "global-store/G_DATA_UPDATE_CLAIM",
    }),
    skin(){
      return this.$store.getters['appConfig/skin'];
    },
        // Is attention for Logistic and Human Talent
        tabStatus() {
      return this.$route.meta.status ?? 0;
    },
  },
}
</script>

<style scoped>
.image-message {
  max-width: 17rem !important;
  min-width: 17rem !important;
  max-height: 17rem !important;
}
.image-disabled {
  filter: grayscale(100%);
}

.custom-size-sub-title {
  color: #7A7A7A;
  font-size: 18px;
  font-weight: 400;
}
.custom-size-sub-data {
  font-size: 25px;
  word-wrap: break-word;
  hyphens: auto;
}
.formatDiv {
  padding: 10px;
  border-radius: 5px;
  height: 200px;
  max-height: 200px;
  overflow-y: auto;
}
.descriptionDiv {
  border: 1px solid #d8d6de;
}

.grayColorWhite {
  border: 1px solid #d8d6de;
}

.grayColorDark {
  border: 1px solid #464646;
}
</style>
@/views/commons/employee-claims/services/claim.service.js