<template>
  <div class="container-icon" :style="propContainer" @click="clickMe()">
    <svg
      :width="width"
      :height="height"
      class="my-icon"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.1343 54.2686C42.1202 54.2686 54.2686 42.1202 54.2686 27.1343C54.2686 12.1484 42.1202 0 27.1343 0C12.1484 0 0 12.1484 0 27.1343C0 42.1202 12.1484 54.2686 27.1343 54.2686Z"
        fill="#CC2334"
      />
      <path
        d="M11.9992 18.0562C11.7129 18.0419 11.4551 18.271 11.4551 18.5717C11.4551 18.8008 11.6126 19.0012 11.8417 19.0728C12.5863 19.2733 14.2903 19.846 15.6649 21.2493C14.5337 22.0225 13.7748 23.3399 13.7748 24.8147C13.7748 27.206 15.7078 29.139 18.0991 29.139C20.4904 29.139 22.4234 27.206 22.4234 24.8147C22.4234 19.0155 14.2044 18.185 11.9992 18.0562Z"
        fill="#202020"
      />
      <path
        d="M42.2694 18.0562C40.0643 18.185 31.8452 19.0155 31.8452 24.8004C31.8452 27.1916 33.7783 29.1247 36.1695 29.1247C38.5608 29.1247 40.4938 27.1916 40.4938 24.8004C40.4938 23.3112 39.7493 22.0082 38.6038 21.235C39.9784 19.8317 41.668 19.259 42.4269 19.0585C42.656 19.0012 42.8135 18.8008 42.8135 18.5573C42.8135 18.271 42.5701 18.0419 42.2694 18.0562Z"
        fill="#202020"
      />
      <path
        d="M34.5802 41.3387C34.1792 41.3387 33.7783 41.1382 33.5492 40.7659C33.4633 40.6228 31.3298 37.3724 27.1343 37.3724C23.0391 37.3724 20.8054 40.6514 20.7195 40.7946C20.3472 41.353 19.5883 41.5105 19.0298 41.1382C18.4714 40.7659 18.3139 40.0214 18.6719 39.4486C18.7864 39.2625 21.6931 34.9238 27.1343 34.9238C32.7044 34.9238 35.4966 39.2768 35.6111 39.4629C35.9691 40.0357 35.7973 40.7946 35.2245 41.1526C35.024 41.2671 34.7949 41.3387 34.5802 41.3387Z"
        fill="#202020"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      required: false,
      default: 50,
    },
    height: {
      type: Number,
      required: false,
      default: 50,
    },
    value: {
      type: Number,
      required: false,
      default: 1,
    },
  },

  computed: {
    propContainer() {
      return { width: this.width + 10 + "px", height: this.height + 10 + "px" };
    },
  },

  data() {
    return {};
  },

  methods: {
    clickMe() {
      this.$emit("clickMe", this.value);
    },
  },
};
</script>

<style scoped>
.container-icon {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-icon {
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.my-icon:hover {
  transform: scale(1.2);
}
</style>