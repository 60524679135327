<template>
  <b-row>
    <b-col cols="2" offset="1">
      <AngryEmojiIcon
        class="emoji-icon"
        :width="width"
        :height="height"
        :value="1"
        @clickMe="setRate"
        :class="survey.rate == 1 ? 'border-selected' : ''"
      />
      <p class="emoji-description">Bad</p>
    </b-col>
    <b-col cols="2">
      <SadEmojiIcon
        class="emoji-icon"
        :width="width"
        :height="height"
        :value="2"
        @clickMe="setRate"
        :class="survey.rate == 2 ? 'border-selected' : ''"
      />
      <p class="emoji-description">Regular</p>
    </b-col>
    <b-col cols="2">
      <NormalEmojiIcon
        :width="width"
        :height="height"
        :value="3"
        @clickMe="setRate"
        class="emoji-icon"
        :class="survey.rate == 3 ? 'border-selected' : ''"
      />
      <p class="emoji-description">Good</p>
    </b-col>
    <b-col cols="2">
      <HappyEmojiIcon
        :width="width"
        :height="height"
        :value="4"
        @clickMe="setRate"
        class="emoji-icon"
        :class="survey.rate == 4 ? 'border-selected' : ''"
      />
      <p class="emoji-description">Very Good</p>
    </b-col>
    <b-col cols="2">
      <SuperHappyEmojiIcon
        :width="width"
        :height="height"
        :value="5"
        @clickMe="setRate"
        class="emoji-icon"
        :class="survey.rate == 5 ? 'border-selected' : ''"
      />
      <p class="emoji-description">Excellent</p>
    </b-col>
    <b-col cols="12" class="mt-2">
      <b-form-textarea
        v-model="survey.comment"
        placeholder="Write a comment..."
        rows="1"
        no-resize
        max-rows="1"
        :disabled="isAnswered"
      ></b-form-textarea>
    </b-col>
    <b-col cols="12" class="mt-2 d-flex justify-content-center" v-if="!isAnswered">
      <b-button
        variant="primary"
        class="float-right"
        @click="sendFeedback"
        >Send</b-button
      >
    </b-col>
  </b-row>
</template>

<script>
import AngryEmojiIcon from "@/icons/AngryEmojiIcon.vue";
import SadEmojiIcon from "@/icons/SadEmojiIcon.vue";
import NormalEmojiIcon from "@/icons/NormalEmojiIcon.vue";
import HappyEmojiIcon from "@/icons/HappyEmojiIcon.vue";
import SuperHappyEmojiIcon from "@/icons/SuperHappyEmojiIcon.vue";

export default {
  components: {
    SuperHappyEmojiIcon,
    HappyEmojiIcon,
    NormalEmojiIcon,
    SadEmojiIcon,
    AngryEmojiIcon,
  },
  props: {
    survey: {
      type: Object,
      required: true,
      default: function () {
        return {
          rate: 0,
          comment: "",
        };
      },
    },
  },
  data() {
    return {
      width: 20,
      height: 20,
      isAnswered: false,
      onHover: null,
    };
  },
  mounted() {
    if (this.survey.rate !== null && this.survey.rate !== 0) {
      this.isAnswered = true;
    }

    this.setRate(this.survey.rate);
  },
  methods: {
    setRate(value) {
      if (this.isAnswered === false) {
        this.$set(this.survey, "rate", value);
      }
    },
    hover(value) {
      this.onHover = value;
    },
    async sendFeedback() {
      const result = await this.showConfirmSwal();
      if (result.isConfirmed) {
        if (this.survey.rate === null || this.survey.rate === 0) {
          this.showToast(
            "danger",
            "top-right",
            "Error",
            "CheckIcon",
            `Please select a rate`
          );
          return;
        }

        this.$emit("feedback", {
          rate: this.survey.rate,
          comment: this.survey.comment,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.emoji-icon {
  cursor: pointer;
}
.emoji-icon:hover {
  transform: scale(1.5);
  transition: transform 0.2s;
}
.border-selected {
  border: 2px solid #007bff;
  border-radius: 50%;
}
.emoji-description {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  margin-top: 13px;
  margin-left: -15px;
}
</style>