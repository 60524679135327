<template>
  <div class="container-icon" :style="propContainer" @click="clickMe()">
    <svg
      :width="width"
      :height="height"
      class="my-icon"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.1343 54.2686C42.1202 54.2686 54.2686 42.1202 54.2686 27.1343C54.2686 12.1484 42.1202 0 27.1343 0C12.1484 0 0 12.1484 0 27.1343C0 42.1202 12.1484 54.2686 27.1343 54.2686Z"
        fill="#EFB025"
      />
      <path
        d="M18.0991 29.1247C20.4873 29.1247 22.4234 27.1886 22.4234 24.8004C22.4234 22.4121 20.4873 20.476 18.0991 20.476C15.7108 20.476 13.7748 22.4121 13.7748 24.8004C13.7748 27.1886 15.7108 29.1247 18.0991 29.1247Z"
        fill="#202020"
      />
      <path
        d="M36.1696 29.1247C38.5578 29.1247 40.4939 27.1886 40.4939 24.8004C40.4939 22.4121 38.5578 20.476 36.1696 20.476C33.7813 20.476 31.8452 22.4121 31.8452 24.8004C31.8452 27.1886 33.7813 29.1247 36.1696 29.1247Z"
        fill="#202020"
      />
      <path
        d="M27.1343 41.3386C21.5643 41.3386 18.7721 36.9857 18.6575 36.7996C18.2996 36.2268 18.4714 35.4679 19.0441 35.1099C19.6169 34.752 20.3615 34.9238 20.7195 35.4965C20.8054 35.6397 22.9389 38.8901 27.1343 38.8901C31.2725 38.8901 33.5349 35.5109 33.5492 35.4822C33.9215 34.9238 34.6804 34.752 35.2388 35.1242C35.7973 35.4965 35.9691 36.2554 35.5968 36.8139C35.4823 37 32.5898 41.3386 27.1343 41.3386Z"
        fill="#202020"
      />
    </svg>
  </div>
</template>
    
    <script>
export default {
  props: {
    width: {
      type: Number,
      required: false,
      default: 50,
    },
    height: {
      type: Number,
      required: false,
      default: 50,
    },
    value: {
      type: Number,
      required: false,
      default: 4,
    },
  },

  computed: {
    propContainer() {
      return { width: this.width + 10 + "px", height: this.height + 10 + "px" };
    },
  },

  data() {
    return {};
  },

  methods: {
    clickMe() {
      this.$emit("clickMe", this.value);
    },
  },
};
</script>
    
    <style scoped>
.container-icon {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-icon {
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.my-icon:hover {
  transform: scale(1.2);
}
</style>